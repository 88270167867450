<template>
  <div id="app">
    
    <navigation :categories="categories" :firstpage="firstPage" />
    <router-view
      :homepage="home"
      :firstpage="firstPage"
      :categories="categories"
      v-slot="{ Component }"
    >
      <transition :name="transName">
        <component
          :key="$route.params.section + $route.params.category"
          :is="Component"
        />
      </transition>
    </router-view>
    <apropos :firstpage="firstPage" :apropos="apropos" />
  </div>
</template>

<script>
var data = JSON.parse(
  document.querySelector("script[type='application/json']").innerHTML
);
// console.log(data);
import navigation from "@/views/Navigation.vue";
import apropos from "@/views/APropos.vue";

export default {
  
  
  data() {
    return { ...data, transName: "fade", isMobile: false, fullscreen : false };
  },
  components: { navigation, apropos },
  watch: {
    $route: {
      handler: function (to, from) {
        var title = "Pierre Bonard";
       
        var description = "yo"; // eslint-disable-line no-unused-vars
        if (to.name == "about") {
          title += " - A Propos";
        } else if (to.name == "Section") {
          title += " - " +  this.decodeHtml(this.getCategory.title) + ", "+ this.decodeHtml(this.getCurrentProject.title);
          this.reorderCats();
        } else if (to.name == "Menu") {
          title += " - " + this.decodeHtml(this.getCategory.title);
          this.reorderCats();

        }
        document.title = title || "Your Website";
        // document.querySelector("meta[name=description]").setAttribute("content", description);
        // eslint-disable-line no-unused-vars
        if (to.name) {
          if (to.name == "about") {
            this.transName = "slowfade";
          } else if (from.name == "about") {
            this.transName = "quickfade";
          } else {
            this.transName = "fade";
          }
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.firstPage) {
      setTimeout(
        function () {
          this.firstPage = false;
        }.bind(this),
        3000
      );
    }
    this.isMobile = window.innerWidth < 600;
    window.addEventListener(
      "resize",
      function () {
        this.isMobile = window.innerWidth < 600;
      }.bind(this)
    );
  },
  methods: {
    decodeHtml(html) {
      var txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    },
  },
};
</script>

<style lang="scss">
html,
body {
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
}

a {
  color: black;
}

.fade-enter-active,
.fade-leave-active {
  // opacity: 1;
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-active {
  opacity: 0;
}

.slowfade-enter-active,
.slowfade-leave-active {
  opacity: 1;
  transition: opacity 0.5s ease;
  transition-delay: 500ms;
}
.slowfade-enter-from,
.slowfade-leave-active {
  opacity: 1;
}

.quickfade-enter-active,
.quickfade-leave-active {
  // opacity: 1;
  transition: opacity 0s ease;
}

.invisible {
  opacity: 0 !important;

  transition: opacity 500ms;
}

@media screen and (min-width: $breakpoint) {
  html,
  body {
    font-size: 16px;
  }
}
</style>
