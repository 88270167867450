<template>
  <div id="homeContainer">
    <div
      v-for="(img, i) in homepage"
      :key="i"
      :class="[
        'imgContainer',
        img.isfullscreen ? 'fullscreen' : 'centered',
        i == currImage ? 'active' : '',
      ]"
    >
      <imagehelper
        :image="img.images"
        class="lazyload"
        :alt="img.title + ',' + img.annee"
      />
    </div>
    <div id="nameContainer" :class="[firstpage ? '' : 'invisible']">
      Pierre Bonard
    </div>
    <imagenav
      :infos="homepage"
      :currentImage="currImage"
      @next="nav(true)"
      @previous="nav(false)"
      :class="!firstpage ? '' : 'invisible'"
    />
  </div>
</template>

<script>
import imagehelper from "./Image.vue";
import imagenav from "./ImageNav.vue";
import lazyload from "lazyload";
export default {
  data() {
    return {
      currImage: 0,
    };
  },
  components: {
    imagehelper,
    imagenav,
  },
  props: ["homepage", "firstpage"],
  mounted() {
    // eslint-disable-next-line no-unused-vars
    var lazy = new lazyload(); //es-lint
  },
  methods: {
    nav(forward) {
      this.currImage += (forward ? 1 : -1) + this.homepage.length;
      this.currImage %= this.homepage.length;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#homeContainer {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
}
#nameContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  white-space: nowrap;
  mix-blend-mode: difference;
  color: white;
  _color: black;
  font-weight: bold;
  text-align: center;
  opacity: 1;
  transition: opacity 500ms;
}

.imgContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  // display: none;
}
.imgContainer img {
  opacity: 0;
  transition: opacity 500ms;
}
.imgContainer.active img {
  opacity: 1;
}

.fullscreen img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
.centered {
  padding: 100px;
  box-sizing: border-box;
}
.centered img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}

@media screen and (max-width: 600px) {
  .centered {
    padding: 10vw;
  }
}
</style>
