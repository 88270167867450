<template>
  <div id="aproposcontainer">
    <div v-if="!$root.fullscreen" id="links" :class="!firstpage? '' : 'invisible'">
      <span class="aproposlink">
        <router-link :to="{ name: 'Home' }">Pierre Bonard</router-link>
      </span>
      <span class="aproposlink">
        <router-link :to="{ name: 'about' }">À Propos</router-link>
      </span>
    </div>
    <div id="bioContainer">
      <div id="bio" v-html="apropos.bio"></div>
      <div id="expos_personelles">
        <span class="sectionTitle">EXPOSITIONS</span>
        <eventlist :events="apropos.expos_personelles" class="last" />
      </div>
      <div id="acquisitions">
        <span class="sectionTitle">ACQUISITIONS PUBLIQUES</span>
        <eventlist :events="apropos.acquisitions_publiques" class="last" />
      </div>
      <div id="bibliographie">
        <span class="sectionTitle">BIBLIOGRAPHIE</span>
        <eventlist :events="apropos.bibliographie" class="last" />
      </div>
    </div>
  </div>
</template>
<script>
import eventlist from "@/views/EventList";
export default {
  props: ["firstpage", "apropos"],
  components: { eventlist },
  watch: {
    $route: {
      // eslint-disable-next-line no-unused-vars
      handler: function (to, from) {
        if (to.name) {
          if (to.name == "about") {
            document.getElementById("aproposcontainer").classList.add("active");
          } else {
            document
              .getElementById("aproposcontainer")
              .classList.remove("active");
          }
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
#aproposcontainer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0);
  z-index: 10000;
  box-sizing: border-box;
  padding: 20px;
  transition: background-color 300ms ease-in-out 200ms, transform 500ms;
  transform: translateY(calc(100% - 59px));
  pointer-events: none;
}
.sectionTitle {
  margin: 32px 0px 20px 0px;
  display: block;
}

#aproposcontainer.active {
  transform: translateY(0);
  background-color: rgba(255, 255, 255, 1);
  transition: transform 500ms, background-color 300ms;
  #links {
    background-color: white;
  }
  #bioContainer {
    pointer-events: all;
    overflow-y : auto;
  }
}
#links {
  transition: opacity 500ms;
  display: flex;
  position: absolute;
  top: 0px;
  width: 100%;
  padding: 20px 20px 5px 20px;
  box-sizing: border-box;
  z-index: 300;
  left: 0px;
  pointer-events: none;
  span {
    flex-grow: 2;
    a {
      color: black;
      text-decoration: none;
      pointer-events: all;
    }
  }
  span:last-of-type {
    text-align: right;
  }
}

#bioContainer {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  left: 0px;
  padding-top: 38px;
  overflow-y: hidden;
  padding : 50px 20px 20px 20px;
}

@media screen and (min-width: $breakpoint) {
  #aproposcontainer {
    transform: translateY(0px);
    #bioContainer {
      opacity: 0;
      transition: opacity 200ms;
      background-color: white;
      padding : 50px 20px 20px 20px;
    }
    #links {
      transition:none;
    }
  }
  #aproposcontainer.active {
    background-color: unset;
    #bioContainer {
      opacity: 1;
      overflow-y: auto;
    }
    #links {
      transition: background-color 50ms 500ms;
      box-shadow      : 0px 5px 15px 10px #fff;
    }
  }
}
</style>