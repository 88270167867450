<template>
  <img v-if="image"
    :data-srcset="generateSizes(image.imgs)"
    class="lazyload"
    :width="image.width"
    :height="image.height"
    :sizes="sizes"
    :alt="alt"
    :src="
      'data:image/svg+xml;utf8,<svg width=&quot;' +
      image.width +
      '&quot; height=&quot;' +
      image.height +
      '&quot; viewBox=&quot;0 0 ' +
      image.width +
      ' ' +
      image.height +
      '&quot; version=&quot;1.1&quot; xmlns=&quot;http://www.w3.org/2000/svg&quot; xmlns:xlink=&quot;http://www.w3.org/1999/xlink&quot;></svg>'
    "
  />
</template>
<script>
export default {
  props: ["image", "alt", "sizes"],
  methods: {
    generateSizes(sizes) {
      var s = "";
      // console.log(sizes)
      sizes.forEach((element) => {
        if (s != "") s += ", ";
        s += "" + element.url + " " + element.w + "w";
      });
      return s;
    },
  },
};
</script>