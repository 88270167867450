<template>
  <div :key="getCurrentProject.title">
    <div id="allImageContainer" :class="{ fullscreen: $root.fullscreen }">
      <div
        v-for="(img, i) in getCurrentProject.images"
        :key="i + '-' + img.width"
        :class="['imgContainer', i == currImage ? 'active' : '']"
      >
        <transition name="imgfade" mode="in-out">
          <imagehelper
            :image="img.images"
            class="lazyload"
            :key="i + '-' + img.width"
            :ref="i == currImage ? 'active' : ''"
            :alt="img.title + ',' + img.annee"
            :sizes="$root.fullscreen ? '100vw' : '33vw'"
            @click="goFullscreen"
            v-show="i == currImage"
          />
        </transition>
      </div>
      <imagenav
        :infos="getCurrentProject.images"
        :currentImage="currImage"
        @next="nav(true)"
        @previous="nav(false)"
        :fullscreen="fullscreen"
      />
    </div>
    <div
      id="backgroundThumbsContainer"
      :class="{ fullscreen: $root.fullscreen }"
    >
      <div
        v-for="index in 35"
        :key="index"
        class="placeholder"
        ref="thumbContainer"
        :style="{
          top: 20 * Math.floor((index - 1) / 7) + '%',
          left: (100 * Math.floor((index - 1) % 7)) / 7 + '%',
        }"
      ></div>
    </div>
  </div>
</template>

<script>
import imagenav from "./ImageNav";
import imagehelper from "./Image.vue";

import lazyload from "lazyload";
export default {
  data() {
    var order = [
      31, 3, 15, 19, 1, 33, 5, 29, 27, 21, 13, 7, 20, 14, 4, 30, 2, 32, 8, 26,
      0, 34, 22, 12, 28, 6,
    ];
    return {
      currImage: 0,
      order: order,
      currImgInOrder: 0,
      fullscreen: false,
    };
  },
  components: {
    imagehelper,
    imagenav,
  },
  props: ["homepage", "categories"],
  mounted() {
    new lazyload();
  },
  methods: {
    nav(forward) {
      console.log(document.querySelector(".active").childNodes[0]);
      if (
        !document
          .querySelector(".active")
          .childNodes[0].classList.contains("thumbnailed")
      ) {
        document
          .querySelector(".active")
          .childNodes[0].classList.add("thumbnailed");
        var image = document
          .querySelector(".active")
          .childNodes[0].cloneNode(true);
        document
          .querySelectorAll(".placeholder")[this.order[this.currImgInOrder]].appendChild(image);
        image.dataset.imageID = this.currImage;
        image.addEventListener("click", this.goToImage);
        this.currImgInOrder++;
      }

      this.currImage +=
        (forward ? 1 : -1) + this.getCurrentProject.images.length;
      this.currImage %= this.getCurrentProject.images.length;
    },
    goToImage(e) {
      this.currImage = parseInt(e.target.dataset.imageID);
    },
    goFullscreen() {
      this.$root.fullscreen = true;
    },
    resetLazyLoad() {
      setTimeout(function () {
        new lazyload();
      }, 20);
    },
  },
  watch: {
    $route: {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      handler: function (to, from) {
        if (to.params.section) {
          this.resetLazyLoad();
          this.currImage = 0;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.imgContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  pointer-events: none;
  padding: 70px 30px 70px 30px;
  box-sizing: border-box;
}
.imgContainer img {
  // opacity: 0;
  // transition: opacity 500ms;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}
.imgContainer.active  {
  pointer-events: all;
}

.centered {
}

#backgroundThumbsContainer {
  display: none;
}

@media screen and (min-width: $breakpoint) {
  .imgContainer {
    // padding: 100px;
    // display: none;
    width: 33.333vw;
    height: 60vh;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    z-index: 300;
    img {
      cursor: zoom-in;
    }
  }
  #backgroundThumbsContainer.fullscreen {
    display: none;
  }
  #allImageContainer.fullscreen {
    background-color: black;
    width: 100%;
    height: 100vh;
    .imgContainer {
      width: 90vw;
      height: 90vh;

      z-index: 300;
      img {
        cursor: default;
      }
    }
  }
  #backgroundThumbsContainer {
    display: block;
    position: absolute;
    top: 70px;
    left: 0px;
    z-index: -1;
    height: calc(100% - 140px);
    box-sizing: border-box;
    // margin: 50px 0px;
    width: 100%;
    z-index: 300;
    pointer-events: none;
    .placeholder,
    .thumbContainer {
      position: absolute;
      // background-color: red;
      width: 14.28%;
      height: calc((100vh - 100px) / 5);
    }
    .placeholder {
      padding: 20px;
      box-sizing: border-box;
      box-sizing: border-box;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
        pointer-events: all;
        cursor: pointer;
      }
    }

    .imageinCenter {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
  }
}

.imgfade-enter-active,
.imgfade-leave-active {
  transition: opacity 0.5s ease;
  // opacity: 1;
}
.imgfade-enter,
.imgfade-enter-from,
.imgfade-leave-to {
  opacity: 0;
}

</style>