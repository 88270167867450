<template>
  <div id="navigationContainer" v-if="!$root.fullscreen">
    <div id="topContainer">
      <ul id="sectionLinks" :class="!firstpage ? '' : 'invisible'">
        <li
          v-for="(category, i) in categories"
          :key="i"
          :class="[
            getCategory != category ? 'notactive' : '',
            getCategory == category && $route.name == 'Menu'
              ? 'activeMenu'
              : '',

            $route.name == 'Home' ? 'firstPage' : '',
          ]"
        >
          <router-link
            :to="{
              name: 'Menu',
              params: {
                category: getendURL(category),
              },
            }"
            :class="[
              getCategory != category ? 'notactive' : '',
              getCategory == category && $route.name == 'Menu'
                ? 'activeMenu'
                : '',
            ]"
          >
            {{ category.title.toUpperCase() }}
          </router-link>
          <ul class="pageIndicators">
            <li
              class="catsub"
              v-if="category.subtitle != '' && $route.name != 'Menu'"
              v-html="category.subtitle"
            />
            <li
              v-for="(section, j) in category.sections"
              :key="j"
              :class="[
                getCurrentProject == section ? 'activePageName' : '',
                category.subtitle != '' ? 'hascatsub' : '',
                'pageName',
              ]"
            >
              <span class="subtitle" v-if="section.sous_titre != ''">
                {{ section.sous_titre }}&nbsp;
              </span>
              <span>{{ section.title }} </span>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ["categories", "firstpage"],
  methods: {},
  computed: {},
};
</script>

<style scoped lang="scss">
#navigationContainer {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 10;
  pointer-events: none;
  a {
    color: black;
    text-decoration: none;
    pointer-events: all;
    transition: color 500ms;
  }
  a.router-link-active {
    color: black;
  }
  a.insection {
    color: grey;
  }
}

#topContainer {
  position: absolute;
  top: 0px;
  padding: 20px 20px 5px 20px;
  box-sizing: border-box;
  // width: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}
ul#sectionLinks {
  list-style: none;
  margin: 0px;
  padding: 0px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  li {
    margin: 0px 11px;
    padding: 0px;
    display: inline-block;
    position: relative;
  }
  li a.notactive {
    color: grey;
  }
  li a.activeMenu {
    color: black;
  }
  li.firstPage a {
    color: black;
  }
}

ul#sectionLinks .pageIndicators {
  position: absolute;
  margin: 0px;
  text-align: center;
  padding: 0px;
  width: 400%;
  transform: translateX(-37.5%);
  // margin-top: -2px;
  margin-top: 0px;
  li.pageName {
    display: none;
    margin: 0px;
    padding: 0px;
    // line-height: 0.8em;
    span {
      display: block;
    }
  }
  li.pageName.activePageName {
    display: block;
  }
}

.catsub {
  margin-top: 0px !important;
}
.notactive .pageIndicators li.catsub {
  display: none !important;
}

li.pageName.hascatsub span {
  display: inline-block !important;
}

@media (any-hover: hover) {
  li a:hover {
    color: black;
  }
}
</style>