export default {
    methods: {
        getendURL(p) {
            var split = p.url.split("/");
            var beforelast = split[split.length - 2];
            return beforelast;
        },
        getcatURL(p) {
            // console.log(p)
            var split = p.url.split("/");
            var beforelast = split[split.length - 3];
            return beforelast;
        },
        reorderCats() {


            var currcat = -1;
            for (var i = 0; i < this.$root.categories.length; i++) {
                if (this.getendURL(this.$root.categories[i]) == this.$route.params.category) {

                    currcat = i;
                    break;
                }
            }
            if(currcat != -1) {
                var cat = this.$root.categories.splice(currcat, 1);
                this.$root.categories.splice(1, 0, cat[0] )
            }
        }
    },
    computed: {
        getCurrentProject() {
            var pID = this.$route.params.section;
            var cat = this.$route.params.category;
            var currSection = false;
            this.categories.forEach((el) => {
                for (var i = 0; i < el.sections.length; i++) {
                    if (this.getendURL(el.sections[i]) == pID && this.getcatURL(el.sections[i]) == cat) {

                        currSection = el.sections[i];
                        // console.log(currSection)
                        break;
                    }
                }
            });

            return currSection;
        },
        getCategory() {
            var currcat;
            var cat = this.$route.params.category;
            for (var i = 0; i < this.$root.categories.length; i++) {
                if (this.getendURL(this.$root.categories[i]) == cat) {

                    currcat = this.$root.categories[i];
                    break;
                }
            }

            return currcat
        },
        hasSubtitle() {
            return this.getCategory.subtitle != "";
          },
    }
}