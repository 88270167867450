<template>
  <div class="eventList">
    <div
      class="yearSection"
      v-for="(year, index) in sorted"
      v-bind:key="index + year.year"
    >
      <div class="yearContainer">
        {{ year.year }}
      </div>
      <div class="events">
        <div
          class="event"
          v-for="(event, index) in year.events"
          v-bind:key="index.event"
        >
          <span v-html="event.name" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["events"],
  computed: {
    sorted() {
      return this.sortByYear(this.events);
    },
  },
  methods: {
    sortByYear(thing) {
      var years = [];
      thing.forEach(function (e) {
        var didntmatch = true;
        for (var i = 0; i < years.length; i++) {
          if (years[i].year == e.year.trim()) {
            years[i].events.push({
              name: e.name,
            });
            didntmatch = false;
            break;
          }
        }
        if (didntmatch) {
          years.push({
            year: e.year.trim(),
            events: [
              {
                name: e.name,
              },
            ],
          });
        }
      });
      years.sort(function (a, b) {
        if (a.year > b.year) return -1;
        else return 1;
      });
      return years;
    },
  },
};
</script>

<style scoped lang="scss">
.eventList {
  // margin-bottom: 20px;
  // font-size: 70%;
  // line-height: 28px;
}
.eventList.last {
  margin-bottom: 0px;
}
.yearSection {
  display: flex;
  // margin-top: 10px;
}
.yearContainer {
  flex-basis: 50px;
  flex-shrink: 0;
}
.event {
  margin-bottom: 10px;
  cursor: default;
}

@media screen and (min-width: $breakpoint) {
  .eventList {
    // width: 50vw;
  }
}
</style>
<style>
.event p {
  margin: 0;
  padding: 0;
}
</style>