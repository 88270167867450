<template>
  <div>
    <div v-if="hasSubtitle" v-html="getCategory.subtitle" class="catsub" />
    <ul
      class="pageIndicators"
      :class="getCategory.sections.length + 'sections'"
    >
      <li
        v-for="(section, j) in getCategory.sections"
        :key="j"
        :class="getClass(j, getCategory.sections.length)"
      >
        <router-link
          :to="{
            name: 'Section',
            params: {
              category: getcatURL(section),
              section: getendURL(section),
            },
          }"
        >
          <template v-if="section.sous_titre != ''">
            <span class="subtitle" v-html="section.sous_titre" />
          </template>
          <span v-html="getDesktopName(section.title)" />
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: [],
  computed: {},
  methods: {
    getDesktopName(n) {
      if (this.$root.isMobile) {
        console.log("mob");
        return n;
      } else {
        var parts = n.split(" ");
        var newName = parts.splice(0, 1) + "<br>";
        parts.forEach((element) => {
          newName += element + " ";
        });
        return newName;
      }
    },
    getClass(i, l) {
      var offset = 0;
      if (l % 2 == 1) {
        offset = 2;
      }
      if (l == 5) {
        offset = 0;
      }
      return (i + offset) % 3 != 2
        ? "tworow" + " " + ((i + offset) % 3 == 0 ? "left" : "right")
        : "onerow";
    },
  },
};
</script>

<style lang="scss" scoped>
.catsub {
  font-size: 100%;
  position: absolute;
  left: 50%;
  top: 38px;
  width: 100%;
  text-align: center;
  transform: translateX(-50%);
  cursor: default;
}

ul {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  justify-content: space-around;
  box-sizing: border-box;
  padding: 70px 30px 70px 30px;
}
ul,
li {
  list-style: none;
  margin: 0px;
  text-align: center;
}
li {
  font-size: 130%;
  padding: 0px;
  .subtitle {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 0.9em;
    display: block;
  }
  a {
    color: black;
    text-decoration: none;
  }
}

@media screen and (min-width: $breakpoint) {
  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: unset;
    width: 100%;
    padding: 100px 0px;
    font-size: 200%;
    justify-content: space-between;
  }
  li {
    margin: 20px 0px;
    line-height: 0.8em;
    letter-spacing: -0.05em;
    .subtitle {
      line-height: 1.9em;
    }
    a {
      display: inline-block;
      // max-width: 200px;
      text-align: center;
    }
  }
  .tworow {
    flex-basis: 40%;
  }
  .tworow.left {
    text-align: center;
  }
  .tworow.right {
    text-align: center;
  }
  .onerow {
    flex-basis: 100%;
  }
  .catsub {
    font-size: 200%;
    top: 48px;
  }
}
@media screen and (min-width: 3*$breakpoint) {
  ul {
    // font-size: 200%;
  }
}
</style>
