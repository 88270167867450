import { createWebHistory, createRouter } from "vue-router";
import home from "@/views/Home.vue";
import section from "@/views/Section.vue";
import empty from "@/views/empty.vue"
import MenuPage from "@/views/MenuPage.vue"
const routes = [
    {
        path: (process.env.NODE_ENV !== 'production'?"/pierrebonard":"")+"/",
        name: "Home",
        component: home,
        props: true
    },
    {
        path: (process.env.NODE_ENV !== 'production'?"/pierrebonard":"")+"/a-propos",
        name: "about",
        component: empty,
        props: true
    },
    {
        path:  (process.env.NODE_ENV !== 'production'?"/pierrebonard":"")+"/sections/:category/:section/",
        name: "Section",
        component: section,
        props: true
    },
    {
        path:  (process.env.NODE_ENV !== 'production'?"/pierrebonard":"")+"/sections/:category/",
        name: "Menu",
        component: MenuPage,
        props: true
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) { // eslint-disable-line no-unused-vars
        // console.log(to, from, savedPosition)
        return new Promise((resolve, reject) => { // eslint-disable-line no-unused-vars
            setTimeout(() => {
                // console.log(reject)
                resolve({ left: 0, top: 0 })
            }, 500)
        });
    }
});




export default router;