<template>
  <div>
    <div
      :class="['leaveFullscreen', $root.fullscreen ? 'fullscreen' : '']"
      @click="leaveFullscreen"
    >
      <img
        :src="
          (prod !== 'production' ? '/pierrebonard' : '') +
          '/site/templates/imgs/cross.png'
        "
        alt=""
        width="16"
        height="16"
      />
    </div>
    <div
      :class="[
        'buttonDesktop',
        'left',
        $root.firstPage ? 'notActive' : '',
        $root.fullscreen ? 'fullscreen' : '',
      ]"
      @click="$emit('previous')"
    ></div>
    <div
      :class="[
        'buttonDesktop',
        'right',
        $root.firstPage ? 'notActive' : '',
        $root.fullscreen ? 'fullscreen' : '',
      ]"
      @click="$emit('next')"
    ></div>
    <div id="navigatorContainer">
      <div class="button" @click="$emit('previous')">
        <img
          :src="
            (prod !== 'production' ? '/pierrebonard' : '') +
            '/site/templates/imgs/rightss.png'
          "
          alt=""
          width="16"
          height="16"
        />
      </div>
      <div :class="['info', $root.fullscreen ? 'fullscreen' : '']">
        <slot v-for="(info, i) in infos" :key="i">
          <transition name="fade">
            <div v-if="currentImage == i" class="cont">
              <div class="number" style="flex-basis: 100%">
                {{ i + 1 }}/{{ infos.length }}
              </div>
              <div class="title" v-html="info.title"></div>

              <template v-if="has(info.annee)">
                <div class="desktopSeparator">,&nbsp;</div>
                <div class="year">{{ info.annee }}</div>
              </template>
              <!-- <div class="desktopSeparator">,&nbsp;</div>
              <div class="format">{{ info.format }}</div> -->
              <template v-if="has(info.technique)">
                <div class="desktopSeparator">,&nbsp;</div>
                <div class="technique">{{ info.technique }}</div>
              </template>
            </div>
          </transition>
        </slot>
      </div>
      <div class="button" @click="$emit('next')">
        <img
          :src="
            (prod !== 'production' ? '/pierrebonard' : '') +
            '/site/templates/imgs/leftss.png'
          "
          alt=""
          width="16"
          height="16"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["infos", "currentImage"],
  mounted() {
    // console.log(this.infos);
  },
  methods: {
    has(el) {
      return el.trim() != "";
    },
    leaveFullscreen() {
      this.$root.fullscreen = false;
    },
  },
  computed: {
    prod() {
      return process.env.NODE_ENV;
    },
  },
};
</script>

<style scoped lang="scss">
#navigatorContainer {
  position: absolute;
  bottom: 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0px 10px 20px 10px;
  box-sizing: border-box;
  z-index: 20;
}
.button {
  padding: 10px;
  cursor: pointer;
  font-size: 200%;
  line-height: 1em;
  font-weight: bold;
  position: relative;
  top: -5px;
  display: block;
}
.info {
  flex-grow: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .cont {
    // position: absolute;
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    position: absolute;
    bottom: 0px;
  }
  .desktopSeparator {
    display: block;
  }
}
.info.fullscreen {
  color: white;
}
.desktopSeparator {
  display: none;
}

.buttonDesktop {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 50%;
  height: 100%;
  cursor: w-resize;
}
.buttonDesktop.fullscreen {
  z-index: 400;
}
.buttonDesktop.right {
  left: 50%;
  cursor: e-resize;
}

@media screen and (min-width: $breakpoint) {
  .info {
    .cont {
      flex-direction: row;
      justify-content: center;
    }
  }
  .desktopSeparator {
    display: block;
  }
  .button {
    display: none;
  }
  .buttonDesktop.notActive {
    display: none;
  }
  .leaveFullscreen {
    color: white;
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 600;
    cursor: pointer;
    padding: 20px;
    display: none;
  }
  .leaveFullscreen.fullscreen {
    display: block;
  }
}

@media screen and (max-width: $breakpoint) {
  #navigatorContainer {
    padding: 0px 10px 50px 10px;
  }
}
</style>